import { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Layout, Menu, Image, Button, Typography } from "antd";
import { GiftOutlined, ScheduleOutlined } from "@ant-design/icons";
import logo from "../assets/images/logo.png";

import { useAccount, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

const { Header, Content, Footer, Sider } = Layout;

const items = [
  {
    icon: <ScheduleOutlined />,
    key: "draw-link",
    label: <NavLink to="/draw">Draw</NavLink>,
  },
  {
    icon: <GiftOutlined />,
    key: "giveaway-link",
    label: <NavLink to="/giveaway">Giveaway</NavLink>,
  },
];

const MainLayout = () => {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Image
          src={logo}
          style={{ margin: 16 }}
          width="75%"
          preview={false}
          alt="Logo"
        />
        <Menu
          theme="dark"
          defaultSelectedKeys={["draw-link"]}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: "#ffffff",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {!isConnected ? (
            <Button
              type="primary"
              style={{ margin: "0 40px" }}
              onClick={() => connect()}
            >
              Connect Wallet
            </Button>
          ) : (
            <>
              <Typography.Text copyable={true}>{address}</Typography.Text>
              <Button
                type="primary"
                style={{ margin: "0 40px" }}
                onClick={() => disconnect()}
              >
                Disconnect
              </Button>
            </>
          )}
        </Header>
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          OODLE TV Giveaway Draw ©2023 Created by OODLE TV Team
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
