import React, { useState, useEffect } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import DrawService from "../services/DrawService";
import GiveawayService from "../services/GiveawayService";

import {
  Breadcrumb,
  Button,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Popconfirm,
  Table,
  notification,
  Select,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  SaveOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const DrawEditPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  const [giveawaysData, setGiveawaysData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [saving, setSaving] = React.useState(false);
  const [giveawayNameList, setGiveawayNameList] = useState([]);

  useEffect(() => {
    if (params.id !== "new") {
      setGiveawaysData([]);
      form.setFieldsValue({ title: "", date: "" });

      fetchDraw();
    } else {
    }

    getAllGiveawayData();
  }, []);

  const fetchDraw = () => {
    DrawService.get(params.id).then(({ data }) => {
      const GiveawaysDataToDisplay = [];
      data.giveaways.forEach((value) => {
        GiveawaysDataToDisplay.push({
          key: uuidv4(),
          name: value.name,
          quantity: value.quantity,
        });
      });
      setGiveawaysData(GiveawaysDataToDisplay);
      form.setFieldsValue({ title: data.title, date: dayjs(data.date) });
    });
  };

  const getAllGiveawayData = () => {
    GiveawayService.getAll()
      .then(({ data }) => {
        setGiveawayNameList(
          data.map((value) => {
            return {
              value: value.name,
              label: value.name,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isEditing = (record) => record.key === editingKey;

  const handleEditGiveaway = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const handleSaveGiveaway = async (key) => {
    try {
      const row = await form.validateFields(["name", "quantity"]);
      const newData = [...giveawaysData];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setGiveawaysData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setGiveawaysData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAddGiveaway = () => {
    const newData = [...giveawaysData];
    newData.push({
      key: uuidv4(),
      name: "New Giveaway Item",
      quantity: 1,
    });
    setGiveawaysData(newData);
    setEditingKey("");
  };

  const handleDeleteGiveaway = (key) => {
    const newData = [...giveawaysData];
    const index = newData.findIndex((item) => key === item.key);
    newData.splice(index, 1);
    setGiveawaysData(newData);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber min={1} />
      ) : (
        <Select
          // defaultValue="lucy"
          // style={{
          //   width: 300,
          // }}
          // onChange={handleChange}
          options={giveawayNameList}
        />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              icon={<SaveOutlined />}
              size="small"
              type="default"
              onClick={() => handleSaveGiveaway(record.key)}
              style={{
                marginRight: 8,
              }}
            />
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button
                icon={<CloseOutlined />}
                size="small"
                type="default"
                danger
              />
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              icon={<EditOutlined />}
              size="small"
              type="default"
              disabled={editingKey !== ""}
              onClick={() => handleEditGiveaway(record)}
              style={{
                marginRight: 8,
              }}
            />
            <Popconfirm
              title="Are you sure? This giveaway item will be deleted."
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDeleteGiveaway(record.key)}
            >
              <Button
                icon={<DeleteOutlined />}
                size="small"
                type="default"
                danger
              />
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSave = (values) => {
    setSaving(true);
    values.giveaways = giveawaysData;

    if (params.id === "new") {
      DrawService.create(values).then((res) => {
        if (res.status === 200) {
          notification.success({
            message: "Action done",
            description: "You've updated draw details successfully.",
          });
          navigate("/draw");
        } else {
          notification.error({
            message: "Action failed",
            description: "Sorry, something went wrong, please try again later.",
          });
        }
      });
    } else {
      DrawService.update(params.id, values).then((res) => {
        if (res.status === 200) {
          notification.success({
            message: "Action done",
            description: "You've updated draw details successfully.",
          });
          navigate("/draw");
        } else {
          notification.error({
            message: "Action failed",
            description: "Sorry, something went wrong, please try again later.",
          });
        }
      });
    }
  };

  const handleBack = () => {
    navigate(-1, {
      replace: true,
    });
  };

  return (
    <>
      <Breadcrumb style={{ margin: "8px 0" }}>
        <Breadcrumb.Item>System</Breadcrumb.Item>
        <Breadcrumb.Item>
          <NavLink to="/draw">Draw</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: 24, minHeight: 360, background: "#ffffff" }}>
        <PageHeader
          title="Edit Draw"
          subTitle="Edit draw details here"
          style={{ padding: 0 }}
        />
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={handleSave}
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please input your title!",
              },
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>
          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
                message: "Please input giveaway draw date!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item label="Giveaways" name="giveaways">
            <>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddGiveaway}
              >
                Add Giveaway
              </Button>
              <Table
                style={{ marginTop: 18 }}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                dataSource={giveawaysData}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </>
          </Form.Item>
          <Form.Item label="Action">
            <Button
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
              loading={saving}
            >
              Save
            </Button>
            <Button
              icon={<ArrowLeftOutlined />}
              style={{
                marginLeft: 12,
              }}
              onClick={handleBack}
            >
              Back
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default DrawEditPage;
