import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import MainLayout from "./pages/MainLayout";

import DrawPage from "./pages/DrawPage";
import DrawEditPage from "./pages/DrawEditPage";
import GiveawayPage from "./pages/GiveawayPage";
import GiveawayEditPage from "./pages/GiveawayEditPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" index element={<Navigate to="/draw" />} />
        <Route path="/draw" element={<DrawPage />} />
        <Route path="/draw/:id" element={<DrawEditPage />} />
        <Route path="/giveaway" element={<GiveawayPage />} />
        <Route path="/giveaway/:id" element={<GiveawayEditPage />} />
      </Route>
    </Routes>
  );
}

export default App;
