import http from "../http-common";

const getAll = () => {
  return http.get("/giveaway");
};

const get = (id) => {
  return http.get(`/giveaway/${id}`);
};

const create = (data) => {
  return http.post("/giveaway", data);
};

const update = (id, data) => {
  return http.put(`/giveaway/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/giveaway/${id}`);
};

const removeAll = () => {
  return http.delete(`/giveaway`);
};

const findByName = (name) => {
  return http.get(`/giveaway/name/${name}`);
};

const findByNameAndUpdate = (name, data) => {
  return http.put(`/giveaway/name/${name}`, data);
};

const GiveawayService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByName,
  findByNameAndUpdate,
};

export default GiveawayService;
