import React, { useState, useEffect } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import GiveawayService from "../services/GiveawayService";

import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  notification,
  Switch,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const GiveawayEditPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (params.id !== "new") {
      getGiveawayData();
    } else {
      form.setFieldsValue({
        name: "",
        levels: [],
        totalQuantity: 1,
      });
    }
  }, []);

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const getGiveawayData = () => {
    const id = params.id;

    GiveawayService.get(id)
      .then(({ data }) => {
        form.setFieldsValue({
          name: data.name,
          levels: data.levels,
          totalQuantity: data.totalQuantity,
          excludeWinners: data.excludeWinners,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = (values) => {
    console.log(values);
    setSaving(true);

    if (params.id === "new") {
      GiveawayService.create(values)
        .then(({ data }) => {
          notification.success({
            message: "Action done",
            description: "You've created the new giveaway item successfully.",
          });
          navigate("/giveaway");
        })
        .catch(() => {
          notification.error({
            message: "Action failed",
            description: "Something went wrong, please try again later.",
          });
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      GiveawayService.update(params.id, values)
        .then(({ data }) => {
          notification.success({
            message: "Action done",
            description: "You've created the new giveaway item successfully.",
          });
          navigate("/giveaway");
        })
        .catch(() => {
          notification.error({
            message: "Action failed",
            description: "Something went wrong, please try again later.",
          });
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const handleBack = () => {
    navigate(-1, {
      replace: true,
    });
  };

  const options = [
    {
      label: "Level 1",
      value: "Level 1",
    },
    {
      label: "Level 2",
      value: "Level 2",
    },
    {
      label: "Level 3",
      value: "Level 3",
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: "8px 0" }}>
        <Breadcrumb.Item>System</Breadcrumb.Item>
        <Breadcrumb.Item>
          <NavLink to="/giveaway">Giveaway</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: 24, minHeight: 360, background: "#ffffff" }}>
        <PageHeader
          title="Edit Giveaway"
          subTitle="Edit giveaway details here"
          style={{ padding: 0 }}
        />
        <Form
          form={form}
          name="giveaway"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={handleSave}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input the giveaway name!",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Levels"
            name="levels"
            rules={[
              {
                required: true,
                message: "Please input giveaway levels!",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              options={options}
            />
          </Form.Item>
          <Form.Item
            label="Total Quantity"
            name="totalQuantity"
            rules={[
              {
                required: true,
                message: "Please input the total quantity of this item!",
              },
            ]}
          >
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <Form.Item
            label="Exclude Winners"
            name="excludeWinners"
            valuePropName="checked"
            initialValue={true}
            extra="Exclude winners to win additional prizes"
          >
            <Switch defaultChecked onChange={onChange} />
          </Form.Item>
          <Form.Item label="Action">
            <Button
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
              loading={saving}
            >
              Save
            </Button>
            <Button
              icon={<ArrowLeftOutlined />}
              style={{
                marginLeft: 12,
              }}
              onClick={handleBack}
            >
              Back
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default GiveawayEditPage;
