import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { useNetwork, useSigner, useAccount } from "wagmi";
import { giveaway } from "../utils/giveaway";
import DrawService from "../services/DrawService";
import WinnerService from "../services/WinnerService";

import {
  Breadcrumb,
  Button,
  Table,
  Space,
  Popconfirm,
  Tag,
  notification,
  Modal,
  Spin,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  QuestionOutlined,
  SendOutlined,
  CheckOutlined,
  SyncOutlined,
  DownloadOutlined,
  LinkOutlined,
  ToolOutlined,
} from "@ant-design/icons";

const DrawPage = () => {
  const { data: signer } = useSigner();
  const { chain } = useNetwork();
  const { isConnected } = useAccount();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawResult, setDrawResult] = useState([]);
  const [executingDraw, setExecutingDraw] = useState(false);

  useEffect(() => {
    getAllDraws();
  }, []);

  const getAllDraws = () => {
    setLoading(true);

    DrawService.getAll()
      .then(({ data }) => {
        const temp = data.map((value, index) => {
          return {
            id: value._id,
            title: value.title,
            date: dayjs(value.date).format("MM/DD/YYYY"),
            giveaways: value.giveaways,
            status: value.status,
            tx: value.tx,
          };
        });
        setData(temp);
      })
      .catch(() => {
        notification.error({
          message: "Failed to load data",
          description: "Please check the Network Connection or Server Status.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAdd = () => {
    navigate("/draw/new");
  };

  const handleDelete = (id) => {
    DrawService.remove(id).then((res) => {
      if (res.status === 200) {
        notification.success({
          message: "Action done",
          description: "You've updated draw details successfully.",
        });
        getAllDraws();
      } else {
        notification.error({
          message: "Action failed",
          description: "Sorry, something went wrong, please try again later.",
        });
      }
    });
  };

  const handleEdit = (id) => {
    navigate(`/draw/${id}`);
  };

  const handleExecuteDraw = async (data) => {
    if (!isConnected) {
      notification.warning({
        message: "Wallet not connected",
        description: "Please connect the wallet to execute the draw.",
      });
      return;
    }

    setExecutingDraw(true);

    notification.info({
      message: "Starting to execute the draw",
      description:
        "We are gonna generate the Random number from blockchain to select the user. Please confirm the transaction.",
      duration: 30,
      placement: "topLeft",
    });

    const result = await giveaway(data, signer, chain.id);
    if (result === false) {
      setExecutingDraw(false);
      return;
    } else {
      setDrawResult(result);
    }

    showModal();

    data.status = true;
    DrawService.update(data.id, data)
      .then((res) => {
        if (res.status === 200) {
          notification.success({
            message: "Updated the draw status",
            description: "Updated the draw status",
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Something went wrong",
        });
      });

    setExecutingDraw(false);
  };

  const handleViewDrawResult = (data) => {
    setLoading(true);

    DrawService.get(data.id)
      .then(({ data }) => {
        setDrawResult(data.result);
        showModal();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetWinners = () => {
    WinnerService.removeAll()
      .then((data) => {
        if (data.status === 200) {
          notification.success({
            message: "Action done",
            description: "You've reset the winners successfully.",
          });
        } else {
          notification.error({
            message: "Action failed",
            description: "Sorry, something went wrong, please try again later.",
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Action failed",
          description: "Sorry, something went wrong, please try again later.",
        });
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Giveaways",
      key: "giveaways",
      children: [
        {
          title: "Name",
          dataIndex: "giveaways",
          render: (text, record, index) => {
            return text
              .map((value, index, array) => {
                return `${value.name}\n`;
              })
              .join("");
          },
        },
        {
          title: "Quantity",
          dataIndex: "giveaways",
          render: (text, record, index) => {
            return text
              .map((value, index, array) => {
                return `${value.quantity}\n`;
              })
              .join("");
          },
        },
      ],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return status ? (
          <Tag icon={<CheckOutlined />} color="success">
            Drawn
          </Tag>
        ) : (
          <Tag icon={<SyncOutlined spin />} color="processing">
            Pending
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => {
        return record.status ? (
          <Space size="small">
            <Button icon={<EditOutlined />} size="small" disabled />
            <Button icon={<DeleteOutlined />} size="small" danger disabled />
          </Space>
        ) : (
          <Space size="small">
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={() => handleEdit(text)}
            />
            <Popconfirm
              title="Are you sure? This draw will be deleted."
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(text)}
              icon={<QuestionOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} size="small" danger />
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Execute",
      dataIndex: "status",
      key: "result",
      render: (status, data) => {
        return status ? (
          <Space size="small">
            <Button
              icon={<EyeOutlined />}
              size="small"
              onClick={() => handleViewDrawResult(data)}
            />
            <Button
              icon={<LinkOutlined />}
              size="small"
              href={`https://sepolia.etherscan.io/tx/${data.tx}`}
            />
          </Space>
        ) : (
          <Button
            icon={<SendOutlined />}
            size="small"
            onClick={() => handleExecuteDraw(data)}
          />
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumb
        style={{ margin: "8px 0" }}
        items={[{ title: "System" }, { title: "Draw" }]}
      />
      <Spin spinning={executingDraw} tip="Executing the draw...">
        <div style={{ padding: 24, minHeight: 360, background: "#ffffff" }}>
          <PageHeader
            title="Draw"
            subTitle="Manage giveaway draws here"
            style={{ padding: 0 }}
            extra={[
              <Button
                type="primary"
                icon={<ToolOutlined />}
                key="reset-winners-button"
                onClick={handleResetWinners}
              >
                Reset Winners
              </Button>,
              <Button
                type="primary"
                icon={<PlusOutlined />}
                key="add-draw-button"
                onClick={handleAdd}
              >
                Add Draw
              </Button>,
            ]}
          />

          <Table
            style={{
              marginTop: 18,
              width: "100%",
              overflowX: "auto",
              whiteSpace: "pre",
            }}
            columns={columns}
            dataSource={data}
            loading={loading}
            rowKey={(record) => `draw-key-${record.id}`}
            size="small"
          />
        </div>
      </Spin>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Typography.Title level={5}>Draw Result</Typography.Title>
            <CSVLink data={drawResult} filename="Draw Result">
              <Button type="primary" size="small" icon={<DownloadOutlined />}>
                Download CSV
              </Button>
            </CSVLink>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        width="80%"
      >
        <Table
          const
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
            },
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Subscription",
              dataIndex: "productId",
              key: "productId",
              render: (productId) => {
                if (productId.toString() === "90773") {
                  return "SKILLED - Monthly";
                } else if (productId.toString() === "88429") {
                  return "PRO - 6 Months";
                } else {
                  return "LEGEND - 12 Months";
                }
              },
            },
            {
              title: "Win",
              dataIndex: "win",
              key: "win",
            },
          ]}
          dataSource={drawResult}
          rowKey={(record) => `draw-result-key-${record.id}`}
          size="small"
        />
      </Modal>
    </>
  );
};

export default DrawPage;
