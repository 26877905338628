import { Contract } from "@ethersproject/contracts";
import abi from "./abi.json";

const contractAddress = {
  11155111: "0xcd3fafdfa2f8191bf7540fdf3654756e3ab4c718", // Sepolia
};

const contractObj = (chainId, signerOrProvider) => {
  try {
    return new Contract(contractAddress[chainId], abi, signerOrProvider);
  } catch (error) {
    return null;
  }
};

const options = {
  gasLimit: 1000000,
};

const requestRandomWords = async (chainId, signer) => {
  const contract = contractObj(chainId, signer);

  const tx = await contract.requestRandomWords(options);
  const receipt = await tx.wait();
  return receipt;
};

const lastRequestId = async (chainId, provider) => {
  const contract = contractObj(chainId, provider);

  return await contract.lastRequestId();
};

const getRequestStatus = async (chainId, provider, requestId) => {
  const contract = contractObj(chainId, provider);

  return await contract.getRequestStatus(requestId);
};

export { requestRandomWords, lastRequestId, getRequestStatus };
