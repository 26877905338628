import http from "../http-common";

const getAll = () => {
  return http.get("/winner");
};

const get = (id) => {
  return http.get(`/winner/${id}`);
};

const create = (data) => {
  return http.post("/winner", data);
};

const update = (id, data) => {
  return http.put(`/winner/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/winner/${id}`);
};

const removeAll = () => {
  return http.delete(`/winner`);
};

const findByTitle = (title) => {
  return http.get(`/winner?title=${title}`);
};

const WinnerService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
};

export default WinnerService;
