import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GiveawayService from "../services/GiveawayService";

import {
  Breadcrumb,
  Button,
  Table,
  Space,
  Popconfirm,
  notification,
  Tag,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  PlusOutlined,
  EditOutlined,
  QuestionOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const GiveawayPage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllGiveawayData();
  }, []);

  const getAllGiveawayData = () => {
    setLoading(true);

    GiveawayService.getAll()
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        notification.error({
          message: "Failed to load data",
          description: "Please check the Network Connection or Server Status.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    navigate(`/giveaway/${id}`);
  };

  const handleDelete = (id) => {
    GiveawayService.remove(id)
      .then((data) => {
        notification.success({
          message: "Action done",
          description: "You've deleted the giveaway item successfully.",
        });
        getAllGiveawayData();
      })
      .catch((err) => {
        notification.error({
          message: "Action failed",
          description: "Sorry, something went wrong, please try again later.",
        });
      });
  };

  const handleAdd = () => {
    navigate(`/giveaway/new`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Levels",
      dataIndex: "levels",
      key: "levels",
      render: (text) => {
        return (
          <Space>
            {text.map((value, index) => {
              return <Tag key={index}>{value}</Tag>;
            })}
          </Space>
        );
      },
    },
    {
      title: "Total Quantity",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
    },
    {
      title: "Exclude Winners",
      dataIndex: "excludeWinners",
      key: "excludeWinners",
      render: (text) => {
        return text ? (
          <Tag icon={<CheckOutlined />} color="success" />
        ) : (
          <Tag icon={<CloseOutlined />} color="error" />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (text) => {
        return (
          <Space size="small">
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={() => handleEdit(text)}
            />
            <Popconfirm
              title="Are you sure? This giveaway item will be deleted."
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(text)}
              icon={<QuestionOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} size="small" danger />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumb
        style={{ margin: "8px 0" }}
        items={[{ title: "System" }, { title: "Giveaway" }]}
      />
      <div style={{ padding: 24, minHeight: 360, background: "#ffffff" }}>
        <PageHeader
          title="Giveaway"
          subTitle="Manage giveaway items here"
          style={{ padding: 0 }}
          extra={[
            <Button
              type="primary"
              icon={<PlusOutlined />}
              key="add-giveaway-button"
              onClick={handleAdd}
            >
              Add Giveaway
            </Button>,
          ]}
        />
        <Table
          style={{
            marginTop: 18,
            width: "100%",
            overflowX: "auto",
          }}
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey={(record) => `giveaway-key-${record._id}`}
          size="small"
        />
      </div>
    </>
  );
};

export default GiveawayPage;
