import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://www.uscreen.io/publisher_api/v1",
  headers: {
    "Content-type": "application/json",
    Authorization: "mSpdqTcBCo9yPBAyLDyd1i59",
  },
});

const getCustomers = (page) => {
  return axiosInstance.get(`/customers?page=${page}`);
};

const getAccesses = (customerId) => {
  return axiosInstance.get(`/customers/${customerId}/accesses`);
};

const UserService = {
  getCustomers,
  getAccesses,
};

export default UserService;
