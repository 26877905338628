import { sha256 } from "crypto-hash";
import GiveawayService from "../services/GiveawayService";
import DrawService from "../services/DrawService";
import UserService from "../services/UserService";
import WinnerService from "../services/WinnerService";
import {
  MEMBERSHIP_PRO,
  MEMBERSHIP_BEGINNER,
  MEMBERSHIP_LEGEND,
} from "./membershipConfig";
import { notification } from "antd";

import { randomContract, saveContract } from "../contracts";

const subscribedUserData = require("./users.json");
// const subscribedUserData = [];

let giveawayData = [];

const getRandomNumberFromChainlink = async (signer, chainId) => {
  try {
    await randomContract.requestRandomWords(chainId, signer);
  } catch (err) {
    notification.error({
      message: "Transaction error",
      description: "You didn't connect wallet or approve the transaction.",
    });
    return false;
  }

  notification.info({
    message: "Random number generation tx confirmed",
    description: "Preparing to select the user randomly. Just a min...",
    duration: 30,
    placement: "topLeft",
  });

  const requestId = await randomContract.lastRequestId(chainId, signer);

  let sum = 0;
  while (1) {
    const randoms = await randomContract.getRequestStatus(
      chainId,
      signer,
      requestId
    );

    if (randoms.fulfilled === true) {
      for (let i = 0; i < randoms.randomWords[0].toString().length; i++) {
        sum = sum + Number(randoms.randomWords[0].toString()[i]);
      }
      break;
    } else continue;
  }
  return sum;
};

const generateRandomNumber = (range, chainlinkRandomNumber) => {
  return Math.floor(Math.random() * chainlinkRandomNumber * range) % range;
};

const getAcceptLevels = (name) => {
  const id = giveawayData.findIndex((giveaway) => {
    return giveaway.name === name;
  });
  let levels = giveawayData[id].levels;
  let acceptLevels = [];
  for (let level of levels) {
    if (level === "Level 1") acceptLevels.push(MEMBERSHIP_LEGEND);
    else if (level === "Level 2") acceptLevels.push(MEMBERSHIP_PRO);
    else acceptLevels.push(MEMBERSHIP_BEGINNER);
  }

  return acceptLevels;
};

const getGiveawayData = async () => {
  const res = await GiveawayService.getAll();
  giveawayData = res.data;
};

const getUserData = async () => {
  let userData = [];

  // Get all users
  let page = 1;
  while (1) {
    let { data } = await UserService.getCustomers(page);
    if (data.length === 0) {
      break;
    }
    userData = [...userData, ...data];
    page++;
  }

  // Get access for the subscribed users
  for (let i = 0; i < userData.length; i++) {
    if (userData[i].subscriber === true) {
      UserService.getAccesses(userData[i].id)
        .then(({ data }) => {
          subscribedUserData.push({
            name: userData[i].name,
            id: userData[i].id,
            email: userData[i].email,
            product_id: data.slice(-1)[0].product_id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};

export const giveaway = async (data, signer, chainId) => {
  getUserData();
  const users = subscribedUserData;
  let giveaways = [];
  const drawResult = [];
  const excludedWinners = [];
  const excludeWinnersItems = [];

  // Get all giveaways
  await getGiveawayData();

  // Get all winners
  const winners = await WinnerService.getAll();
  const concatenatedWinners = [];
  for (let i = 0; i < winners.length; i++) {
    for (let j = 0; j < winners[i].winners.length; j++) {
      concatenatedWinners.push(winners[i].winners[j]);
    }
  }

  // Get giveaways to exclude winners
  for (let i = 0; i < giveawayData.length; i++) {
    if (giveawayData[i].excludeWinners === true) {
      excludeWinnersItems.push(giveawayData[i].name);
    }
  }

  // Check you have enough giveaway quantity for this draw
  for (let i = 0; i < data.giveaways.length; i++) {
    for (let j = 0; j < giveawayData.length; j++) {
      if (data.giveaways[i].name === giveawayData[j].name) {
        if (data.giveaways[i].quantity > giveawayData[j].totalQuantity) {
          notification.error({
            message: `${giveawayData[j].name} is not enough`,
            description: `Please check the ${giveawayData[j].name} quantity.`,
          });
          return false;
        }
      }
    }
  }

  // Prepare the giveaways to start the draw
  for (let i = 0; i < data.giveaways.length; i++) {
    for (let j = 0; j < data.giveaways[i].quantity; j++) {
      giveaways.push(data.giveaways[i].name);
    }
  }

  // Starting to generate the random number
  let chainlinkRandomNumber = await getRandomNumberFromChainlink(
    signer,
    chainId
  );
  if (chainlinkRandomNumber === false) {
    return false;
  }

  notification.info({
    message: "Almost there",
    description: "Ready! Randomly selected users will win the giveaway Items.",
    duration: 30,
    placement: "topLeft",
  });

  for (let i = 0, flag = true; i < giveaways.length; i++) {
    if (flag !== true) {
      break;
    }

    let availableUserExist = false;
    for (let j = 0; j < users.length; j++) {
      let user = users[j];
      let subscription = user.product_id;
      let acceptLevels = getAcceptLevels(giveaways[i]);
      if (acceptLevels.indexOf(subscription) !== -1) {
        availableUserExist = true;
        break;
      }
    }
    if (availableUserExist === false) {
      continue;
    }

    while (1) {
      if (users.length === 0) {
        flag = false;
        break;
      }

      let randomNumber = generateRandomNumber(
        users.length,
        chainlinkRandomNumber
      );

      let user = users[randomNumber];
      let subscription = user.product_id;
      let grudgeMatchProductIds = [90773, 88429, 88431];
      if (grudgeMatchProductIds.indexOf(subscription) !== -1) {
        users.splice(randomNumber, 1);
      }

      let acceptLevels = getAcceptLevels(giveaways[i]);
      if (
        acceptLevels.indexOf(subscription) !== -1 &&
        concatenatedWinners.indexOf(user.id) === -1
      ) {
        drawResult.push({
          id: user.id,
          name: user.name,
          email: user.email,
          productId: user.product_id,
          win: giveaways[i],
        });
        users.splice(randomNumber, 1);
        break;
      }
    }
  }

  notification.success({
    message: "Draw executed successfully",
    description: "You can see the result now.",
    duration: 30,
    placement: "topLeft",
  });

  // Calculate the number of giveaway items used
  const usedQuantityPerItem = {};
  for (let i = 0; i < drawResult.length; i++) {
    let giveawayName = drawResult[i].win;

    if (usedQuantityPerItem[giveawayName] > 0) {
      usedQuantityPerItem[giveawayName]++;
    } else {
      usedQuantityPerItem[giveawayName] = 1;
    }
  }

  for (let i = 0; i < Object.keys(usedQuantityPerItem).length; i++) {
    await GiveawayService.findByNameAndUpdate(
      Object.keys(usedQuantityPerItem)[i],
      {
        deductAmount: usedQuantityPerItem[Object.keys(usedQuantityPerItem)[i]],
      }
    );
  }

  // Save the draw result to the database
  DrawService.update(data.id, { result: drawResult })
    .then((res) => {
      notification.success({
        message: "Draw result saved successfully",
        description: "Draw result saved successfully",
      });
    })
    .catch((err) => {
      notification.error({
        message: "Something went wrong during the result saving",
        description: "Something went wrong during the result saving",
      });
    });

  const drawName = data.title;
  const resultHash = await sha256(JSON.stringify(drawResult));

  // try {
  const receipt = await saveContract.setDrawResult(
    chainId,
    signer,
    drawName,
    resultHash
  );

  data.tx = receipt.transactionHash;
  // } catch (error) {
  //   notification.error({
  //     message: "Something went wrong",
  //   });
  // }

  DrawService.update(data.id, data)
    .then((res) => {
      if (res.status === 200) {
        notification.success({
          message: "Updated the draw tx",
          description: "Updated the draw tx",
        });
      }
    })
    .catch((err) => {
      notification.error({
        message: "Something went wrong",
      });
    });

  // Get the winners from draw result and exclude them
  for (let i = 0; i < drawResult.length; i++) {
    if (excludeWinnersItems.indexOf(drawResult[i].win) !== -1) {
      excludedWinners.push(drawResult[i].id.toString());
    }
  }
  console.log(excludedWinners);
  WinnerService.create({ winners: excludedWinners });

  return drawResult;
};
