import { Contract } from "@ethersproject/contracts";
import abi from "./abi.json";

const contractAddress = {
  11155111: "0x2c8a4D0a22163Dd6bDdf0cCFed6920fb1EF64f32", // Sepolia
};

const contractObj = (chainId, signerOrProvider) => {
  try {
    return new Contract(contractAddress[chainId], abi, signerOrProvider);
  } catch (error) {
    return null;
  }
};

const setDrawResult = async (chainId, signer, name, resultHash) => {
  const contract = contractObj(chainId, signer);

  const tx = await contract.setDrawResult(name, resultHash);
  const receipt = await tx.wait();
  return receipt;
};

export { setDrawResult };
